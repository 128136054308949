import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { GridContainer } from '@jackywxd/shared-material-ui';
import { GridItem } from '@jackywxd/shared-material-ui';
import { Button } from '@jackywxd/shared-material-ui';
import { Parallax } from '@jackywxd/shared-material-ui';

import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
const useStyles = makeStyles(landingPageStyle);

const Termsofuse = ({ data }) => {
  const classes = useStyles();
  return (
    <Layout>
      <Parallax small filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <GridItem>
            <GridContainer justify="center" alignItems="center">
              <div style={{ textAlign: 'center' }}>
                <h1 color="secondary" className={classes.title}>
                  {data.termsofuseJson.title}{' '}
                </h1>
                <h2 className={classes.subtitle}>
                  {data.termsofuseJson.subtitle}{' '}
                </h2>
              </div>
            </GridContainer>
          </GridItem>
        </div>
      </Parallax>
      <main className={classes.main}>
        <div className={classes.container}>
          <div
            className={classes.content}
            dangerouslySetInnerHTML={{
              __html: data.termsofuseJson.content.childMarkdownRemark.html,
            }}
          />
        </div>
      </main>
    </Layout>
  );
};

Termsofuse.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Termsofuse;

export const query = graphql`
  query TermsofuseQuery {
    termsofuseJson {
      title
      subtitle
      content {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
